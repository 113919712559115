// @ts-nocheck
import { Map } from "immutable";
import { Canvas, Project } from "../entities";
import { AllInventoryTypes } from "../data/fetch-inventory";
import { ItemListType } from "./RunItemList/itemListTypes";
import { ItemList } from "./RunItemList";
import sortItemList from "./sortItemList";
import { projectItemListOverrides } from "../utils/partsList";
import {
  addFlatDiscount,
  addPercentDiscounts,
  flatDiscountPresent,
  percentDiscountPresent,
} from "../utils/discount";
import { calculateOverages, totalCableFootage } from "./overages";
import { handleCanvasOverrides, getSubTotal, changeUnitOfMeasure } from "./index";

export function getItemListsByCanvas(
  canvases: Map<string, Canvas>,
  inventoryList: AllInventoryTypes[] | undefined,
  project: Project
): Map<string, ItemListType> {
  if (project?.frozenProject?.itemLists?.itemListsByCanvas) {
    return Map(project.frozenProject.itemLists.itemListsByCanvas);
  }

  if (project?.frozenEstimate?.itemLists?.itemListsByCanvas) {
    return Map(project.frozenEstimate.itemLists.itemListsByCanvas);
  }

  return canvases.map((canvas: any) => {
    canvas.settings = project.settings;
    canvas.currentCanvas = project.currentCanvas;

    const list = changeUnitOfMeasure( getItemList(canvas, inventoryList, project) );

    return list;
  });
}

export function getMasterItemList(itemLists: any) {
  return sortItemList(
   itemLists.reduce((master: any, itemList: any) => {
      Object.values(itemList).forEach((item: any) => {
        if (!master[item.upc]) {
          master[item.upc] = {...item};
        } else {
          master[item.upc].quantity = roundToHundreth(
            item.quantity + master[item.upc].quantity
          );
          master[item.upc].total = roundToHundreth(
            item.total + master[item.upc].total
          );
        }
      });
      return master;
    }, {})
  );
}

export function getItemLists(
  canvases: Map<string, Canvas>,
  inventoryList: AllInventoryTypes[] | undefined,
  project: Project
) {
  return getItemListsByCanvas(canvases, inventoryList, project)
    .entrySeq()
    .map(([index, itemList]) => {
      return itemList;
    });
}

export const roundToHundreth = (number: number) => {
  return Math.round(number * 100) / 100;
};

const getItemListMemo = (memo: any) => (state: any, inventory: any, project: any) => {
  const key = Map({ state, state, project: project }).hashCode();

  if (memo[key]) {
    return memo[key];
  }

  const firstCanvas = project.canvases.first().id;
  project.firstCanvas = firstCanvas;
  let itemList = changeUnitOfMeasure(ItemList(state, inventory, project).getItemList());

  // Calculate passivation cost.
  if (itemList["SERVICE STAINLESS:SVC-Marine-Envir.-PassivationSS"]) {
    const totalCable = totalCableFootage(project);
    const item = itemList["SERVICE STAINLESS:SVC-Marine-Envir.-PassivationSS"];

    if (item.quantity > 0) {
      if (totalCable > 0 && totalCable <= 500) {
        item.price = 125;
      } else if (totalCable > 500 && totalCable <= 1000) {
        item.price = 165;
      } else if (totalCable > 1000) {
        item.price = 165 + Math.ceil((totalCable - 1000) / 500) * 55;
      }

      item.quantity = 1;
      item.total = roundToHundreth(item.quantity * (item?.price || 0));
    }
  }

  itemList = handleCanvasOverrides(itemList, inventory, state.overrides);

  itemList = projectItemListOverrides(itemList, project);

  if (project.settings.get("overrages") === true) {
    itemList = calculateOverages(itemList, inventory, state, project);
  }

  itemList = changeUnitOfMeasure(itemList);

  // Calculate subtotal.
  if (itemList["SubTotal"]) {
    itemList["SubTotal"].total = getSubTotal(itemList);
  }

  // Calculate flat discounts.
  if (flatDiscountPresent(itemList)) {
    itemList = addFlatDiscount(itemList);
  }

  // Calculate percent discount.
  if (percentDiscountPresent(itemList)) {
    itemList = addPercentDiscounts(itemList);
  }

  memo[key] = itemList;

  return itemList;
};

export const getItemList = getItemListMemo({});