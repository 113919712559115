// @ts-nocheck
import { List, Map } from "immutable";
import { calculateCorners } from "./corners";
import {
  getConnectedRuns,
  sortGraphOfRuns,
  walkAllGraphsWithStepFunction,
} from "./graph";

const getRunIndexMemo = (memo) => (run, state) => {
  const key = Map({ run: run, state: state }).hashCode();

  if (memo[key]) {
    return memo[key];
  }

  const connected = state.canvases.reduce((groupsOfRuns, canvas) => {
    const corners = calculateCorners(canvas.runs);
    const connectedRuns = getConnectedRuns(canvas.runs, corners);
    const sortedConnections = connectedRuns.map((group) => {
      return sortGraphOfRuns(group, canvas.runs, corners);
    });
    const connectedRunsIndexes = sortedConnections.map((group) => {
      return walkAllGraphsWithStepFunction(group, ["run"]);
    });

    groupsOfRuns = groupsOfRuns.push(connectedRunsIndexes);

    return groupsOfRuns;
  }, List());

  const runIndex = connected.reduce((index, canvas) => {
    const group = canvas.reduce((groupIndex, group, currentIndex) => {
      if (group.has(run.id)) {
        const runIndex = group.find((groupedRun) => {
          if (run.id !== groupedRun.node.id) {
            return false;
          } else {
            return true;
          }
        });

        if (runIndex) {
          groupIndex = { groupIndex: currentIndex, runIndex: runIndex.index };
        }
      }

      return groupIndex;
    }, null);

    if (group !== null) {
      for (let i = 0; i < group.groupIndex; i++) {
        index += canvas.get(i).size;
      }
      index += group.runIndex;
    }

    return index;
  }, 0);

  memo[key] = runIndex;

  return runIndex;
};

export const getRunIndex = getRunIndexMemo({});
