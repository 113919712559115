// @ts-nocheck
import {
  getRustRescueUpc,
  getNylonStairWasherUpcs,
  getBlackOxideStairWashers,
  getWoodSealerUpc,
  getNylonWasherUpcs,
  getAluminumP2PStairsUpcs,
  getEpoxyKitUpcs,
  getGalvanicScrewProtectionUpc,
  getAluminumP2PInlineUpcs,
  getAlumP2PSaddleBracketUpcs,
  getStainlessSteelToprailUpcs,
  getBasePlateBitForScrewsUpc,
  getBlackOxideCableBundleUpcs,
  getPenofinWoodStainUpcs,
  getAluminumToprailUpcs,
  getWoodToprailUpcs,
  getStairWashers,
  get2507StairWashers,
  getBlackOxideFittingsUpcs,
  getBlackOxideCableKitUpcs,
  getMainScrewUpc,
  getFittingsItems,
  getCableCrimperHolderUpc,
  getStainlessSteelTopRailSpliceUpcs,
  getEndCapUpcs,
  getShrinkTubeUpcs,
  getAluminumMountingHardwareCoverUpcs,
  getCornerUpcs,
  getAluminumTopRailSpliceUpcs,
  getAluminumScrewUpcs,
  getGrommetUpcs,
  getDieCrimperUpcs,
  getEpoxyUpcs,
  getDrillingServiceNames,
  getTouchupPaintUpcs,
  getPostStockUpcs,
  getCableCutterUpcs,
  getCrimperUpc,
  getPassivationServiceName,
  ponyWallPostUpcs,
  getStainlessSteelTopRailBracketUpcs,
  getAluminumFasciaBracketParts,
  lagHardwareUpcs,
  getPassivationSprayUpc,
  undrilledPostUpcs,
  postUpcs,
  boltHardwareUpcs,
  concreteHardwareUpcs,
  concreteHardwareHexboltUpcs,
  concreteHardwareToolUpcs,
  cableUpcs,
  cableUpcs2507,
  get2507FittingsUpcs,
  getShortAnodizedPostStockUpcs,
  getBlackOxideFittingsItems,
} from "./RunItemList/getParts";

export default function sortItemList(itemList) {
  const newList = {};

  const cable = flattenCableUpcs(
    cableUpcs2507(),
    cableUpcs(),
    getBlackOxideCableBundleUpcs(),
    getBlackOxideCableKitUpcs()
  );

  const fittings = flattenFittingsItems(
    getFittingsItems(),
    get2507FittingsUpcs(),
    getBlackOxideFittingsUpcs(),
  );
    
  const boFittings = flattenBlackOxideFittingsItems(
      getBlackOxideFittingsItems()
  );

  const passivation = flattenPassivationItems();
  const tools = flattenToolsUpcs();
  const grommets = flattenGrommetUpcs();
  const shrinkTube = flattenShrinkTubeUpcs();
  const posts = flattenPostUpcs();
  const baseHardware = flattenMountingHardwareUpcs();
  const toprailConnectors = flattenToprailConnectorUpcs();
  const toprail = flattenToprail();
  const toprailComponents = flattenToprailComponents();
  const toprailHardware = flattenToprailHardware();

  const groups = {
    cable: [],
    fittings: [],
    passivation: [],
    tools: [],
    grommets: [],
    posts: [],
    baseComponents: [],
    baseHardware: [],
    toprailConnectors: [],
    toprail: [],
    toprailComponents: [],
    toprailHardware: [],
    other: [],
    subtotal: [],
    regularDiscount: [],
    percentDiscount: [],
    otherCharge: [],
  };

  Object.values(itemList).forEach((item) => {
    const { upc } = item;
    if (cable[upc]) {
      groups.cable.push(item);
      return;
    }

    if (fittings[upc]) {
      groups.fittings.push(item);
      return;
    }

    if (boFittings[upc]) {
      groups.fittings.push(item);
      return;
    }

    if (passivation[upc]) {
      groups.passivation.push(item);
      return;
    }

    if (tools[upc]) {
      groups.tools.push(item);
      return;
    }

    if (grommets[upc]) {
      groups.grommets.push(item);
      return;
    }

    if (shrinkTube[upc]) {
      groups.grommets.push(item);
      return;
    }

    if (posts[upc]) {
      groups.posts.push(item);
      return;
    }

    if (baseHardware[upc]) {
      groups.baseHardware.push(item);
      return;
    }

    if (toprailConnectors[upc]) {
      groups.toprailConnectors.push(item);
      return;
    }

    if (toprail[upc]) {
      groups.toprail.push(item);
      return;
    }

    if (toprailComponents[upc]) {
      groups.toprailComponents.push(item);
      return;
    }

    if (toprailHardware[upc]) {
      groups.toprailHardware.push(item);
      return;
    }

    if (item.type === "otherCharge") {
      groups.otherCharge.push(item);
      return;
    }

    if (item.type === "discount" && !item.discountPercent) {
      groups.regularDiscount.push(item);
      return;
    }

    if (item.type === "discount" && item.discountPercent) {
      groups.percentDiscount.push(item);
      return;
    }

    if (item.type === "subtotal") {
      groups.subtotal.push(item);
      return;
    }

    groups.other.push(item);
  });

  Object.entries(groups).forEach(([group, array]) => {
    if (group === "fittings") {
      array = sortFittings(array);
    }

    if (group === "grommets") {
      array = sortGrommets(array);
    }

    array.forEach((item) => {
      newList[item.upc] = item;
    });
  });

  return newList;
}

function sortGrommets(array) {
  const grommets = flattenGrommetUpcs();

  return array.sort((a, b) => {
    const positionA = grommets[a.upc] ? 1 : 2;
    const positionB = grommets[b.upc] ? 1 : 2;

    return positionA - positionB;
  });
}

function sortFittings(fittings) {
  const order = {
    // SF-Tensioner-Fld-18
    616320732042: 1,
    // SF-Tensioner-Fact-18
    616320731984: 1,
    // SF-Tensioner-Fld-316
    616320732059: 1,
    // SF-Tensioner-Fact-316
    616320731991: 1,
    // SF-Terminal-Fld-18
    616320732066: 2,
    // SF-Terminal-Fact-18
    616320732004: 2,
    // SF-Terminal-Fld-316
    616320732073: 2,
    // SF-Terminal-Fact-316
    616320732011: 2,
    // HW-AcornNut-6mm
    616320730253: 3,
    // HW-AcornNut-8mm
    616320730260: 3,
    // HW-HexNut-6mm
    616320730284: 4,
    // HW-HexJamNut-8mm
    616320730277: 4,
    // HW-QuickNut-18
    616320731755: 5,
    // HW-QuickNut-316
    616320731762: 5,
    // HW-QuickNut-Cover-18
    616320731779: 6,
    // HW-QuickNut-Cover-316
    616320731786: 6,
    // HW-Washer-M6
    616320730307: 7,
    // HW-Washer-M8
    616320730314: 7,
  };

  return fittings.sort((a, b) => {
    const positionA = order[a.upc] ? order[a.upc] : 8;
    const positionB = order[b.upc] ? order[b.upc] : 8;

    return positionA - positionB;
  });
}

function flattenToprailHardware() {
  const items = {};

  traverseSchema(getEpoxyUpcs(), items);
  traverseSchema(getEpoxyKitUpcs(), items);

  const mainScrew = getMainScrewUpc();

  items[mainScrew] = mainScrew;

  const aluminumScrews = getAluminumScrewUpcs();

  traverseSchema(aluminumScrews, items);

  return items;
}

function flattenToprailComponents() {
  const items = {};

  const p2pSaddleBrackets = getAlumP2PSaddleBracketUpcs();
  const aluminumSplice = getAluminumTopRailSpliceUpcs();
  const stainlessSplice = getStainlessSteelTopRailSpliceUpcs();
  const cornerUpcs = getCornerUpcs();
  const endCaps = getEndCapUpcs();

  traverseSchema(p2pSaddleBrackets, items);
  traverseSchema(aluminumSplice, items);
  traverseSchema(stainlessSplice, items);
  traverseSchema(cornerUpcs, items);
  traverseSchema(endCaps, items);

  return items;
}

function flattenToprail() {
  const aluminum = getAluminumToprailUpcs();
  const stainlessSteel = getStainlessSteelToprailUpcs();
  const wood = getWoodToprailUpcs();
  const p2pinline = getAluminumP2PInlineUpcs();
  const p2pstairs = getAluminumP2PStairsUpcs();
  const woodStain = getPenofinWoodStainUpcs();
  const woodSealer = getWoodSealerUpc();
  const items = {};

  traverseSchema(aluminum, items);
  traverseSchema(p2pinline, items);
  traverseSchema(p2pstairs, items);
  traverseSchema(stainlessSteel, items);
  traverseSchema(wood, items);
  traverseSchema(woodStain, items);

  items[woodSealer] = woodSealer;

  return items;
}

function flattenToprailConnectorUpcs() {
  const items = {};
  const stainless = getStainlessSteelTopRailBracketUpcs();
  const aluminum = getAluminumFasciaBracketParts();

  traverseSchema(stainless, items);
  traverseSchema(aluminum, items);

  return items;
}

function flattenMountingHardwareUpcs() {
  const lagUpcs = lagHardwareUpcs();
  const boltUpcs = boltHardwareUpcs();
  const concreteUpcs = concreteHardwareUpcs();
  const hexBoltUpcs = concreteHardwareHexboltUpcs();
  const toolUpcs = concreteHardwareToolUpcs();
  const items = {};

  traverseSchema(lagUpcs, items);
  traverseSchema(boltUpcs, items);
  traverseSchema(concreteUpcs, items);
  traverseSchema(hexBoltUpcs, items);
  traverseSchema(toolUpcs, items);

  traverseSchema(getAluminumMountingHardwareCoverUpcs(), items);

  const bitUpc = getBasePlateBitForScrewsUpc();
  items[bitUpc] = bitUpc;

  const galvanicProtectionUpc = getGalvanicScrewProtectionUpc();
  items[galvanicProtectionUpc] = galvanicProtectionUpc;

  const touchupPaint = getTouchupPaintUpcs();

  traverseSchema(touchupPaint, items);

  return items;
}

function flattenGrommetUpcs() {
  const grommets = getGrommetUpcs();

  return Object.values(grommets).reduce((value, upc) => {
    value[upc] = upc;
    return value;
  }, {});
}

function flattenShrinkTubeUpcs() {
  const shrinkTube = getShrinkTubeUpcs();

  return Object.values(shrinkTube).reduce((value, upc) => {
    value[upc] = upc;
    return value;
  }, {});
}

function flattenPassivationItems() {
  const passivationService = getPassivationServiceName();
  const passivationSpray = getPassivationSprayUpc();
  const rustRescue = getRustRescueUpc();

  return {
    [passivationService]: passivationService,
    [passivationSpray]: passivationSpray,
    [rustRescue]: rustRescue,
  };
}

function flattenToolsUpcs() {
  const crimper = getCrimperUpc();

  const upcs = {
    [crimper]: crimper,
  };

  Object.values(getCableCutterUpcs()).forEach((cutter) => {
    upcs[cutter] = cutter;
  });

  Object.values(getDieCrimperUpcs()).forEach((die) => {
    upcs[die] = die;
  });

  const holder = getCableCrimperHolderUpc();

  upcs[holder] = holder;

  return upcs;
}

function flattenPostUpcs() {
  const upcs = postUpcs();
  const undrilled = undrilledPostUpcs();
  const stock = getPostStockUpcs();
  const anodizedStock = getShortAnodizedPostStockUpcs();
  const ponywall = ponyWallPostUpcs();
  const items = {};

  traverseSchema(upcs, items);
  traverseSchema(undrilled, items);
  traverseSchema(stock, items);
  traverseSchema(anodizedStock, items);
  traverseSchema(ponywall, items);

  Object.values(getDrillingServiceNames()).forEach((service) => {
    items[service] = service;
  });

  return items;
}

export function traverseSchema(posts, items) {
  if (!posts) {
    return;
  }

  if (typeof posts === "number") {
    items[posts] = posts;
    return;
  }

  if (typeof posts === "string") {
    items[posts] = posts;
    return;
  }

  const properties = Object.keys(posts);

  properties.forEach((property) => {
    traverseSchema(posts[property], items);
  });

  return items;
}

function flattenCableUpcs(upcs, ...rest) {
  let values = Object.values(upcs).reduce((value, upc) => {
    value[upc] = upc;
    return value;
  }, {});

  if (rest.length) {
    for (let i = 0; i < rest.length; i++) {
      traverseSchema(rest[i], values);
    }
  }

  return values;
}

function flattenFittingsItems(items, ...rest) {
  const upcs = {};

  const fieldSwagedSchema = {
    singleTensioner: {
      acorn: {
        items: true,
      },
      "quick-nut": {
        items: true,
      },
    },
    doubleTensioner: {
      acorn: {
        items: true,
      },
      "quick-nut": {
        items: true,
      },
    },
  };

  const surfaceMountSchema = {
    singleTensioner: {
      items: true,
    },
    doubleTensioner: {
      items: true,
    },
  };

  const factorySwagedSchema = {
    "one-end-swaged": {
      singleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
      doubleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
    },
    "both-ends-swaged": {
      singleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
      doubleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
    },
  };

  const thruPostSchema = {
    "field-swaged": fieldSwagedSchema,
    "factory-swaged": factorySwagedSchema,
    "fineline-ball": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "fineline-button": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "classic-ball": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "classic-button": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "low-profile": {
      short: {
        items: true,
      },
      long: {
        items: true,
      },
    },
  };

  const swagelessSchema = {
    "type-1": {
      normal: {
        items: true,
      },
      "black-oxide": {
        items: true,
      },
    },
    "type-2": {
      short: {
        items: true,
      },
      long: {
        items: true,
      },
    },
  };

  const fittingsSchema = {
    wood: {
      "thru-post": thruPostSchema,
      swageless: swagelessSchema,
      "surface-mount": surfaceMountSchema,
    },
    metal: {
      "thru-post": thruPostSchema,
      swageless: swagelessSchema,
      "surface-mount": surfaceMountSchema,
    },
  };

  const schema = {
    "7 x 7 - 3/16”": fittingsSchema,
    "1 x 19 - 3/16”": fittingsSchema,
    "1 x 19 - 1/8”": fittingsSchema,
  };

  traverseFittingsSchema(items, schema, upcs);

  rest.forEach((items) => {
    traverseFittingsSchema(items, schema, upcs);
  });

  traverseSchema(getNylonWasherUpcs(), upcs);
  traverseSchema(getNylonStairWasherUpcs(), upcs);
  traverseSchema(getStairWashers(), upcs);
  traverseSchema(get2507StairWashers(), upcs);
  traverseSchema(getBlackOxideStairWashers(), upcs);

  return upcs;
}

function flattenBlackOxideFittingsItems(items, ...rest) {
  const upcs = {};

  const fieldSwagedSchema = {
    singleTensioner: {
      acorn: {
        items: true,
      },
      "quick-nut": {
        items: true,
      },
    },
    doubleTensioner: {
      acorn: {
        items: true,
      },
      "quick-nut": {
        items: true,
      },
    },
  };

  const surfaceMountSchema = {
    singleTensioner: {
      items: true,
    },
    doubleTensioner: {
      items: true,
    },
  };

  const factorySwagedSchema = {
    "one-end-swaged": {
      singleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
      doubleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
    },
    "both-ends-swaged": {
      singleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
      doubleTensioner: {
        acorn: {
          items: true,
        },
        "quick-nut": {
          items: true,
        },
      },
    },
  };

  const thruPostSchema = {
    "field-swaged": fieldSwagedSchema,
    "factory-swaged": factorySwagedSchema,
    "fineline-ball": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "fineline-button": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "classic-ball": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "classic-button": {
      "field-swaged": {
        items: true,
      },
      "factory-swaged": {
        items: true,
      },
    },
    "low-profile": {
      short: {
        items: true,
      },
      long: {
        items: true,
      },
    },
  };

  const swagelessSchema = {
    "type-1": {
      normal: {
        items: true,
      },
      "black-oxide": {
        items: true,
      },
    },
    "type-2": {
      short: {
        items: true,
      },
      long: {
        items: true,
      },
    },
  };

  const fittingsSchema = {
    wood: {
      "thru-post": thruPostSchema,
      swageless: swagelessSchema,
      "surface-mount": surfaceMountSchema,
    },
    metal: {
      "thru-post": thruPostSchema,
      swageless: swagelessSchema,
      "surface-mount": surfaceMountSchema,
    },
  };

  const schema = {
    kits: {
      "1 x 19 - 1/8”": fittingsSchema,
    },
    bundles: {
      "1 x 19 - 1/8”": fittingsSchema,
    }
  };

  traverseFittingsSchema(items, schema, upcs);

  rest.forEach((items) => {
    traverseFittingsSchema(items, schema, upcs);
  });

  traverseSchema(getNylonWasherUpcs(), upcs);
  traverseSchema(getNylonStairWasherUpcs(), upcs);
  traverseSchema(getStairWashers(), upcs);
  traverseSchema(get2507StairWashers(), upcs);
  traverseSchema(getBlackOxideStairWashers(), upcs);

  return upcs;
}

function traverseFittingsSchema(fittings, schema, upcs) {
  if (schema?.items === true) {
    if (fittings.items.length) {
      fittings.items.forEach((item) => {
        upcs[item.upc] = item.upc;
      });
    }
    return;
  }

  const properties = Object.keys(schema);

  if (!fittings) {
    return;
  }

  properties.forEach((property) => {
    traverseFittingsSchema(fittings[property], schema[property], upcs);
  });
}
