// @ts-nocheck
import { Set, Map } from "immutable";
import { isCloseToValue } from ".";

const calculateCornersMemo = (memo) => (runs) => {
  const hash = runs.hashCode();
  
  if (memo[hash]) {
    return memo[hash];
  }

  const corners = runs.reduce((listOfCorners, run, runId) => {
    runs
      .map((checkRun) => {
        if (checkRun.id === runId) {
          return false;
        }

        if (
          isCloseToValue(run.x1, checkRun.x2, 1) &&
          isCloseToValue(run.y1, checkRun.y2, 1)
        ) {
          const angle = calculateAngleOfCorner(run, checkRun);
          return {
            runs: Set([run.id, checkRun.id]),
            points: {
              [run.id]: { id: run.id, type: "1" },
              [checkRun.id]: { id: checkRun.id, type: "2" },
            },
            angle: angle,
          };
        }

        if (
          isCloseToValue(run.x1, checkRun.x1, 1) &&
          isCloseToValue(run.y1, checkRun.y1, 1)
        ) {
          const angle = calculateAngleOfCorner(run, checkRun);
          return {
            runs: Set([run.id, checkRun.id]),
            points: {
              [run.id]: { id: run.id, type: "1" },
              [checkRun.id]: { id: checkRun.id, type: "1" },
            },
            angle: angle,
          };
        }

        if (
          isCloseToValue(run.x2, checkRun.x2, 1) &&
          isCloseToValue(run.y2, checkRun.y2, 1)
        ) {
          const angle = calculateAngleOfCorner(run, checkRun);
          return {
            runs: Set([run.id, checkRun.id]),
            points: {
              [run.id]: { id: run.id, type: "2" },
              [checkRun.id]: { id: checkRun.id, type: "2" },
            },
            angle: angle,
          };
        }

        if (
          isCloseToValue(run.x2, checkRun.x2, 1) &&
          isCloseToValue(run.y1, checkRun.y1, 1)
        ) {
          const angle = calculateAngleOfCorner(run, checkRun);
          return {
            runs: Set([run.id, checkRun.id]),
            points: {
              [run.id]: { id: run.id, type: "2" },
              [checkRun.id]: { id: checkRun.id, type: "1" },
            },
            angle: angle,
          };
        }

        return false;
      })
      .filter((value) => value)
      .forEach((value) => {
        // check for duplicate.
        let duplicate = false;

        listOfCorners.forEach((corner) => {
          let same = true;
          Object.values(corner.points).forEach((point) => {
            if (
              !value.points[point.id] ||
              (value.points[point.id] &&
                value.points[point.id].type !== point.type)
            ) {
              same = false;
            }
          });

          if (same === true) {
            duplicate = true;
          }
        });

        if (!duplicate) {
          listOfCorners.push(value);
        }
      });

    return listOfCorners;
  }, []);

  memo[hash] = corners;

  return corners;
};

export const calculateCorners = calculateCornersMemo({});

const calculateAngle = (run) => {
  const { x1, y1, x2, y2 } = run;

  const dx = x2 - x1;
  const dy = y2 - y1;
  const angle = (Math.atan2(dy, dx) * 180) / Math.PI;

  return angle;
};

export const calculateAngleOfCorner = (run1, run2) => {
  return calculateAngle(run2) - calculateAngle(run1);
};

export function runHasCorners(run, corners) {
  let hasCorners = false;

  if (corners.length) {
    corners.forEach((corner) => {
      if (corner.points[run.id]) {
        hasCorners = true;
      }
    });
  }

  return hasCorners;
}

export function getRunChain(run, corners, runs, chain = Map()) {
  chain = chain.set(run.id, run.id);

  if (corners.length) {
    corners.forEach((corner) => {
      if (corner.points[run.id]) {
        Object.values(corner.points).forEach((point) => {
          if (!chain.has(point.id) && point.id !== run.id) {
            chain = chain.set(point.id, point.id);

            chain = getRunChain(runs.get(point.id), corners, runs, chain);
          }
        });
      }
    });
  }

  return chain;
}

export function getRunCorners(run, corners) {
  let theCorners = [];

  if (corners.length) {
    corners.forEach((corner) => {
      if (corner.points[run.id]) {
        theCorners.push(corner);
      }
    });
  }

  return theCorners;
}

export function getCornerType(run, corner) {
  let type = "";

  Object.values(corner.points).forEach((point) => {
    if (point.id === run.id) {
      type = point.type;
    }
  });

  return type;
}

export function getRunChainFromCorner(
  run,
  corner,
  corners,
  runs,
  chain = Map()
) {
  chain = chain.set(run.id, { run: run, corners: [corner] });

  let cornerRun;

  Object.values(corner.points).forEach((point) => {
    if (point.id !== run.id) {
      cornerRun = runs.get(point.id);

      if (!chain.has(cornerRun.id)) {
        chain = chain.set(cornerRun.id, { run: cornerRun, corners: [corner] });
      }

      corners.forEach((checkCorner) => {
        // New Corner.
        if (checkCorner.points[cornerRun.id] && !checkCorner.points[run.id]) {
          chain = chain.setIn([cornerRun.id, "corners", 1], checkCorner);
          let checkCornerId;

          Object.values(checkCorner.points).forEach((checkPoint) => {
            if (checkPoint.id !== cornerRun.id) {
              checkCornerId = checkPoint.id;
            }
          });
          if (!chain.has(checkCornerId)) {
            chain = chain.set(checkCornerId, {
              run: runs.get(checkCornerId),
              corners: [checkCorner],
            });

            chain = getRunChainFromCorner(
              cornerRun,
              checkCorner,
              corners,
              runs,
              chain
            );
          }
        }
      });
    }
  });

  return chain;
}
